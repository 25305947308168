import React, { Children } from 'react'
import './index.less'
import classnames from 'classnames'

const Card = ({
    children,
    width,
    height,
}) => {

    return (
        <div className="fed-card" style={{ width, height }}>
            {children}
        </div>
    )
}

export default Card