import React, { Fragment } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Section from "../components/Section"
import Card from "../components/Card"
import '../styles/main.less'

const IndexPage = () => (
  <Layout>
    {/* <SEO title="Home" /> */}
    <section className="fed-key-vision">
      <div className="fed-key-vision-cover">
        <div className="fed-slogan">Reactウェブアプリ開発で</div>
        <div className="fed-slogan">Marsが選ばれる理由</div>
      </div>
    </section>

    <Section
      text={
        <Fragment>
          <div className="fed-section-text-side-title">
            <p>Do Not Repeat Yourself</p>
            <p>豊富なUI基礎コンポーネント</p>
          </div>
          <ul className="fed-section-text-side-text">
            <li>
              <p>🌈 実際のエンタプラズ製品から蓄積されたユーザビリティとビジュアルデザイン。</p>
            </li>
            <li>
              <p>📦 Out-of-the-boxな高品質のReactコンポーネント。</p>
            </li>
            <li>
              <p>🛡 TypeScript開発により、完全なるタイプ定義を実現する。</p>
            </li>
            <li>
              <p>🎨 テーマを柔軟にカスタマイズできる仕組みを持っている。</p>
            </li>
          </ul>
        </Fragment>
      }
      image={
        <div className="fed-widgets-banner-img"></div>
      }
      reverse
    />
    <Section
      text={
        <Fragment>
          <div className="fed-section-text-side-title">
            <p>レスポンシブレイアウト対応</p>
            <p>マルチデバイスで表示しても綺麗！</p>
          </div>
          <div className="fed-section-text-side-text">
            <p style={{ marginBottom: 30 }}>
              ビジネスにおいても個人においても、ひとりのユーザーがPC以外にタブレット、スマートフォンを持ち歩くマルチデバイスの時代。
            </p>
            <p>
              すると利用されるアプリケーションにも、それぞれのデバイスで適切に動作する事が期待されます。そこで、その課題解決の一助となるのがMarsとなります。
            </p>
          </div>
        </Fragment>
      }
      image={
        <div className="fed-multi-device-banner-img"></div>
      }
    />
    <Section
      className="fed-section-solution"
      text={
        <Fragment>
          <div className="fed-section-text-side-title">
            <p>Don't need to know EVERYTHING</p>
            <p>快適なワンストープソリューション</p>
          </div>
          <div className="fed-section-text-side-text">
            <p style={{ marginBottom: 30 }}>
              WebpackやBabelなどの詳細知識や煩雑な構築手順不要で、簡単にプロジェクト始動。Mars-cliを利用し、コマンド一つで、すぐエンタープライズレベルのプロジェクト雛形を生成。
            </p>
            <p>
              その他、ルーティング機能や豊富なプラグインを提供。所謂、快適なワンストップソリューションである事が最大の強みです。
            </p>
          </div>
        </Fragment>
      }
      image={
        <div className="fed-solution-img"></div>
      }
      reverse
    />
    <section className="fed-show-cases-section">
      <div className="fed-show-cases-section-title" >事例一覧</div>
      <div className="fed-show-cases-section-list">
        <Link to="/product">
          <Card width={300} height={150}>
            <div className="fed-card-map-img"></div>
            <div style={{ marginTop: 20 }}>不動産売買</div>
          </Card>
        </Link>
        <Link to="/product">
          <Card width={300} height={150}>
            <div className="fed-card-purchase-img"></div>
            <div style={{ marginTop: 20 }}>資材購買</div>
          </Card>
        </Link>
        <Link to="/product" style={{ textDecoration: 'none' }}>
          <Card width={300} height={150}>
            <div className="fed-card-cs-img">
            </div>
            <div style={{ marginTop: 20 }}>カスタマサービスセンター用Webシステムの更改</div>
          </Card>
        </Link>
        <Link to="/product">
          <Card width={300} height={150}>
            <div className="fed-card-editor-img"></div>
            <div style={{ marginTop: 20 }}>クラウド開発用のエディター</div>
          </Card>
        </Link>
        <Link to="/product">
          <Card width={300} height={150}>
            <div className="fed-card-dis-img"></div>
            <div style={{ marginTop: 20 }}>自治体用の防災用クラウドサービス</div>
          </Card>
        </Link>
      </div>
    </section>
  </Layout>
)

export default IndexPage
