import React from 'react'
import './index.less'
import classnames from 'classnames'

const Section = ({
    text,
    image,
    reverse = false,
    className = ""
}) => {

    return (
        <section className={`fed-section ${className}`}>
            <div className={classnames("fed-section-wrapper", {
                "fed-section-wrapper-reverse": reverse
            })}>
                <div className="fed-section-text-side">
                    {text}
                </div>
                <div className="fed-section-image-side">
                    {image}
                </div>
            </div>
        </section>
    )
}

export default Section